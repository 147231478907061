import { Button, ButtonGroup } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

// ..., interval -1 = last interval, interval 0 = current interval, interval 1 = next interval, ...

export interface DateRange {
  startDate: DateTime;
  endDate: DateTime | null;
}

export interface DateQuery {
  after: string;
  before?: string;
}

export interface CreatedAtQuery {
  createdAfter: string;
  createdBefore?: string;
}

export abstract class DateIntervalOption {
  // allowFuture defaults to true
  // When true, an endDate will always be returned
  // When false, endDate will be null when interval is 0
  abstract toDateRange(interval: number): { startDate: DateTime; endDate: DateTime };
  abstract toDateRange(interval: number, allowFuture: boolean): DateRange;

  abstract toDateString(interval: number): string;

  toDateQuery(interval: number, allowFuture = true): DateQuery {
    const { startDate, endDate } = this.toDateRange(interval, allowFuture);
    const query: DateQuery = { after: startDate.toISODate() as string };
    if (endDate) {
      query.before = endDate.plus({ days: 1 }).startOf('day').toISODate() as string;
    }

    return query;
  }

  toCreatedAtQuery(interval: number, allowFuture = false): CreatedAtQuery {
    const { startDate, endDate } = this.toDateRange(interval, allowFuture);
    const query: CreatedAtQuery = { createdAfter: startDate.toISODate() as string };
    if (endDate) {
      query.createdBefore = endDate.plus({ days: 1 }).startOf('day').toISODate() as string;
    }

    return query;
  }

  toMonthRangeString(interval: number): string {
    const { startDate, endDate } = this.toDateRange(interval);
    const format = { month: 'short' };

    return `${startDate.toLocaleString(format)} - ${endDate.toLocaleString(format)}`;
  }
}

export class QuarterInterval extends DateIntervalOption {
  toDateRange(interval: number): { startDate: DateTime; endDate: DateTime };
  toDateRange(interval: number, allowFuture = true): DateRange {
    const startDate = DateTime.local().startOf('quarter').plus({ quarters: interval });
    const endDate = !allowFuture && interval === 0 ? null : startDate.endOf('quarter');
    return { startDate: startDate, endDate: endDate };
  }

  toDateString(interval: number): string {
    return DateTime.local().plus({ quarters: interval }).toFormat('Qq yyyy');
  }
}

export class ThirdInterval extends DateIntervalOption {
  toDateRange(interval: number): { startDate: DateTime; endDate: DateTime };
  toDateRange(interval: number, allowFuture = true): DateRange {
    // The third the current date falls in
    const currentThird = Math.floor((DateTime.local().month - 1) / 4);

    const startDate = DateTime.local()
      .startOf('year')
      .plus({ months: (currentThird + interval) * 4 });
    const endDate = !allowFuture && interval === 0 ? null : startDate.plus({ months: 3 }).endOf('month');
    return { startDate: startDate, endDate: endDate };
  }

  toDateString(interval: number): string {
    const date = DateTime.local().plus({ months: interval * 4 });
    const third = Math.floor((date.month - 1) / 4) + 1;
    return `T${third} ${date.year}`;
  }
}

export class OffsetThirdInterval extends DateIntervalOption {
  // Like ThirdInterval but thirds are shifted a month so that Jan is pushed
  // back into the previous year's T3
  private offsetMonths = -1;

  toDateRange(interval: number): { startDate: DateTime; endDate: DateTime };
  toDateRange(interval: number, allowFuture = true): DateRange {
    // The zero-based third the current date falls in
    const currentThird = Math.floor((DateTime.local().month - 1 + this.offsetMonths) / 4);

    const startDate = DateTime.local()
      .startOf('year')
      .plus({ months: (currentThird + interval) * 4 - this.offsetMonths });
    const endDate = !allowFuture && interval === 0 ? null : startDate.plus({ months: 3 }).endOf('month');
    return { startDate: startDate, endDate: endDate };
  }

  toDateString(interval: number): string {
    const { startDate } = this.toDateRange(interval);
    const third = Math.floor((startDate.month - 1 + this.offsetMonths) / 4) + 1;
    return `T${third} ${startDate.year}`;
  }
}

export const defaultInterval = new OffsetThirdInterval();

export interface IntervalSelectorProps {
  interval: number;
  onIntervalChange: (interval: number) => any;
  intervalOption?: DateIntervalOption;
  changeDelay?: number;
  disableFuture?: boolean;
}

export function DateIntervalSelector(props: IntervalSelectorProps): React.ReactElement {
  const {
    interval,
    onIntervalChange,
    intervalOption = defaultInterval,
    changeDelay = 750,
    disableFuture = false
  } = props;
  const [currentInterval, setCurrentInterval] = useState(interval);
  const [instant, setInstant] = useState(false);

  useEffect(() => {
    setCurrentInterval(disableFuture && interval > 0 ? 0 : interval);
  }, [interval, disableFuture]);

  useEffect(() => {
    if (instant) {
      onIntervalChange(currentInterval);
      setInstant(false);
    } else {
      const timeout = setTimeout(() => {
        onIntervalChange(currentInterval);
      }, changeDelay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [currentInterval]);

  function setIntervalInstant(newInterval: number): void {
    setInstant(true);
    setCurrentInterval(newInterval);
  }

  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          setCurrentInterval(currentInterval - 1);
        }}
      >
        <NavigateBefore />
      </Button>
      <Button onClick={() => setIntervalInstant(0)}>{`${intervalOption.toDateString(
        currentInterval
      )} (${intervalOption.toMonthRangeString(currentInterval)})`}</Button>
      <Button
        disabled={disableFuture && currentInterval >= 0}
        onClick={() => {
          setCurrentInterval(currentInterval + 1);
        }}
      >
        <NavigateNext />
      </Button>
    </ButtonGroup>
  );
}
