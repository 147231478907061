import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  GridSize,
  MenuItem,
  Typography
} from '@material-ui/core';
import { userClient } from 'clients/UserClient';
import { Avatar } from 'components/Avatar';
import { Card } from 'components/Card';
import { UpdateEmailDialog } from 'components/dialogs/UpdateEmailDialog';
import { UpdatePasswordDialog } from 'components/dialogs/UpdatePasswordDialog';
import { ErrorButton } from 'components/ErrorButton';
import { LoadableContainer } from 'components/LoadableContainer';
import { Show } from 'components/visibility/Show';
import { config } from 'Config';
import { ValidationErrors } from 'final-form';
import { cleanErrors, validateMinLength, validateRequired } from 'FormHelpers';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Role } from 'models/Role';
import { User, UserProps } from 'models/User';
import { Select, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { handleRequest } from 'RequestHelpers';

export interface AccountProps {
  currentUser: User;
  onChange?: (user: User) => unknown;
}

export function Account(props: AccountProps): React.ReactElement {
  const { currentUser, onChange = (_: User) => null } = props;
  const { userId } = useParams();
  const snackbar = useSnackbar();
  const [user, setUser] = useState<User>(new User());
  const [updateEmailDialogOpen, setUpdateEmailDialogOpen] = useState(false);
  const [updatePasswordDialogOpen, setUpdatePasswordDialogOpen] = useState(false);
  const [updateAvatarDialogOpen, setUpdateAvatarDialogOpen] = useState(false);

  function internalChange(user: User): void {
    setUser(user);
    onChange(user);
  }

  useEffect(() => {
    document.title = `${config.appName} Account`;
  });

  useEffect(() => {
    if (userId) {
      handleRequest(async () => {
        setUser(await userClient.get(userId));
      }, snackbar).then();
    } else {
      setUser(currentUser);
    }
  }, [userId, currentUser]);

  async function disableUser(): Promise<void> {
    await handleRequest(async () => {
      const newUser = await userClient.delete(user);
      internalChange(newUser);
      snackbar.enqueueSnackbar('User disabled', { variant: 'success' });
    }, snackbar);
  }

  async function enableUser(): Promise<void> {
    await handleRequest(async () => {
      const newUser = await userClient.restore(user);
      internalChange(newUser);
      snackbar.enqueueSnackbar('User enabled', { variant: 'success' });
    }, snackbar);
  }

  function validate(values: UserProps): ValidationErrors {
    return cleanErrors({
      name: validateRequired(values.name) || validateMinLength(values.name, 3),
      displayName: validateRequired(values.displayName) || validateMinLength(values.displayName, 3)
    });
  }

  async function submit(values: UserProps): Promise<void> {
    await handleRequest(async () => {
      const newUser = await userClient.update(user.duplicate(values));
      internalChange(newUser);
      snackbar.enqueueSnackbar('Account Updated', { variant: 'success' });
    }, snackbar);
  }

  const breakpoints: Record<string, GridSize> = {
    xs: 12,
    lg: 6
  };

  const generalCard = (
    <Grid item {...breakpoints}>
      <Card>
        <CardHeader title="General" />
        <Divider />
        <Form
          initialValues={user.toDictionary()}
          validate={validate}
          onSubmit={submit}
          render={({ submitting, hasValidationErrors, handleSubmit, pristine, form }) => (
            <>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Show when={currentUser.hasAccessLevel('admin')}>
                    <Grid item>
                      <TextField name="name" label="Name" fullWidth />
                    </Grid>
                  </Show>
                  <Grid item>
                    <TextField name="displayName" label="Display Name" fullWidth />
                  </Grid>
                  <Show when={currentUser.hasAccessLevel('admin')}>
                    <Grid item>
                      <Select name="roleId" label="Role" fullWidth>
                        {Role.accessibleBy(currentUser.role).map((role) => (
                          <MenuItem key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Show>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={hasValidationErrors || pristine || submitting}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={() => form.reset()}>Reset</Button>
                  </Grid>
                </Grid>
              </CardActions>
            </>
          )}
        />
      </Card>
    </Grid>
  );

  const avatarCard = (
    <Grid item {...breakpoints}>
      <Card>
        <CardHeader title="Profile Picture" />
        <Divider />
        <CardContent>
          <Avatar src={user.avatar} />
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="contained" onClick={() => setUpdateAvatarDialogOpen(true)}>
            Change
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );

  const statusButtonDisabled = user.id === currentUser.id;

  const statusCard = (
    <Grid item style={{ paddingRight: 0 }}>
      <Card>
        <CardHeader title={`Status: ${user.kept ? 'Enabled' : 'Disabled'}`} />
        <Divider />
        <CardActions>
          {user.kept ? (
            <ErrorButton variant="contained" onClick={disableUser} disabled={statusButtonDisabled}>
              Disable
            </ErrorButton>
          ) : (
            <Button color="primary" variant="contained" onClick={enableUser} disabled={statusButtonDisabled}>
              Enable
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );

  const emailCard = (
    <Grid item style={{ paddingRight: 0 }}>
      <Card>
        <CardHeader title="Email" />
        <Divider />
        <CardContent>
          <Typography>
            {user.email} ({user.emailVerified ? 'Verified' : 'Unverified'})
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          {currentUser.hasAccessLevel('admin') ? (
            <Button variant="contained" color="primary" onClick={() => setUpdateEmailDialogOpen(true)}>
              Update
            </Button>
          ) : (
            <Typography>Contact your administrator to update your email address</Typography>
          )}
        </CardActions>
      </Card>
    </Grid>
  );

  const passwordCard = (
    <Grid item {...breakpoints}>
      <Card>
        <CardHeader title="Password" />
        <Divider />
        <CardContent>
          <Button variant="contained" color="primary" onClick={() => setUpdatePasswordDialogOpen(true)}>
            Update Password
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <>
      <LoadableContainer data={user} hideContent loading={user.isNew}>
        <Grid container spacing={3}>
          {generalCard}
          <Grid
            item
            {...breakpoints}
            container
            direction="column"
            spacing={3}
            justify="space-between"
            style={{ paddingRight: 0 }}
          >
            {emailCard}
            <Show when={currentUser.hasAccessLevel('admin')}>{statusCard}</Show>
          </Grid>
          <Show when={currentUser.hasAccessLevel('admin')}>{avatarCard}</Show>
          <Show when={user.id === currentUser.id}>{passwordCard}</Show>
        </Grid>

        <UpdateEmailDialog
          user={user}
          open={updateEmailDialogOpen}
          onClose={() => setUpdateEmailDialogOpen(false)}
          onUserChange={internalChange}
        />
        <UpdatePasswordDialog
          user={user}
          onUserChange={internalChange}
          open={updatePasswordDialogOpen}
          onClose={() => setUpdatePasswordDialogOpen(false)}
        />
        <DropzoneDialog
          open={updateAvatarDialogOpen}
          filesLimit={1}
          acceptedFiles={['image/*']}
          onClose={() => {
            setUpdateAvatarDialogOpen(false);
          }}
          onSave={async (files) => {
            await handleRequest(async () => {
              onChange(await userClient.updateAvatar(user.id, files[0], user.requestParams));
              setUpdateAvatarDialogOpen(false);
            }, snackbar);
          }}
        />
      </LoadableContainer>
    </>
  );
}
