import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { ChevronRight, Clear, Edit, EditOutlined, HelpOutline } from '@material-ui/icons';
import { transactionClient } from 'clients/TransactionClient';
import { DateIntervalSelector, defaultInterval } from 'components/DateIntervalSelector';
import { CorrectTransactionDialog } from 'components/dialogs/CorrectTransactionDialog';
import { TransactionDialog } from 'components/dialogs/TransactionDialog';
import { Table } from 'components/Table';
import { TableView } from 'components/TableView';
import { config } from 'Config';
import { DateTime } from 'luxon';
import { Transaction } from 'models/Transaction';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { abortEffect, handleRequest } from 'RequestHelpers';
import { useTrigger } from 'Utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    amount: {
      minWidth: theme.spacing(4)
    }
  })
);

const correctedIcon = <Edit />;
const correctionIcon = <EditOutlined />;

export interface TransactionsProps {
  currentUser: User;
  isMobileSize?: boolean | null;
}

export function Transactions(props: TransactionsProps): React.ReactElement {
  const { currentUser, isMobileSize } = props;
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [transactions, setTransactions] = useState<Transaction[] | null>();
  const [loading, setLoading] = useState(true);
  const [selectedTransactionId, setSelectedTransactionId] = useState<number | null>();
  const [correctingTransaction, setCorrectingTransaction] = useState(false);
  const [interval, setInterval] = useState(0);
  const [reloadTrigger, triggerReload] = useTrigger();

  useEffect(() => {
    document.title = `${config.appName} Transactions`;
  }, []);

  useEffect(() => {
    const query = { ...defaultInterval.toCreatedAtQuery(interval), include: 'template,user,correctedBy' };
    setLoading(true);
    setTransactions(null);

    return abortEffect(async (signal) => {
      await handleRequest(async () => {
        setTransactions(await transactionClient.getAll({ query, signal }));
      }, snackbar);

      // continue loading if the request was aborting
      if (!signal.aborted) setLoading(false);
    });
  }, [interval, reloadTrigger]);

  useEffect(() => {
    if (selectedTransactionId == null) {
      setCorrectingTransaction(false);
    }
  }, [selectedTransactionId]);

  function renderIcon(transaction: Transaction): React.ReactNode {
    if (transaction.correctsId != null || transaction.correctedBy) {
      return <IconButton>{transaction.correctedBy ? correctedIcon : correctionIcon}</IconButton>;
    }

    return (
      <IconButton disabled={transaction.userId === currentUser.id} onClick={() => setCorrectingTransaction(true)}>
        <Clear />
      </IconButton>
    );
  }

  function renderRow(transaction: Transaction): React.ReactNode {
    return (
      <TableRow key={transaction.id} hover onClick={() => setSelectedTransactionId(transaction.id)}>
        <TableCell>
          {transaction.createdAt.toLocaleString(isMobileSize ? DateTime.DATE_SHORT : DateTime.DATE_FULL)}
        </TableCell>
        <TableCell>
          {transaction.date.toLocaleString(isMobileSize ? DateTime.DATE_SHORT : DateTime.DATE_FULL)}
        </TableCell>
        <TableCell>{transaction.user.name}</TableCell>
        <TableCell>{transaction.template.name}</TableCell>
        <TableCell>
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item className={classes.amount}>
              {transaction.amount}
            </Grid>
            <Grid item>{renderIcon(transaction)}</Grid>
          </Grid>
        </TableCell>
        <TableCell padding="checkbox">
          <ChevronRight />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableView title="Transactions" data={transactions} loading={loading}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DateIntervalSelector interval={interval} onIntervalChange={setInterval} disableFuture={true} />
          </Grid>
          <Grid item>
            <Table stickyToolbar lessBodyPadding>
              <TableHead>
                <TableRow>
                  <TableCell>Created At</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>
                    <Grid container spacing={1} alignItems="center" wrap="nowrap">
                      <Grid item>
                        <div>Amount</div>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          placement="top"
                          title={
                            <Grid container direction="column">
                              <Grid item>
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item>
                                    <Clear />
                                  </Grid>
                                  <Grid item>Click to correct the transaction</Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item>{correctedIcon}</Grid>
                                <Grid item>This transaction has been corrected</Grid>
                              </Grid>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item>{correctionIcon}</Grid>
                                <Grid item>This transaction is a correction</Grid>
                              </Grid>
                            </Grid>
                          }
                        >
                          <HelpOutline fontSize="small" />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>{(transactions || []).map(renderRow)}</TableBody>
            </Table>
          </Grid>
        </Grid>
      </TableView>

      <CorrectTransactionDialog
        transactionId={selectedTransactionId}
        open={correctingTransaction && selectedTransactionId != null}
        onChange={triggerReload}
        onClose={() => setSelectedTransactionId(null)}
      />
      <TransactionDialog
        transactionId={selectedTransactionId}
        onTransactionIdChange={(id) => setSelectedTransactionId(id)}
        currentUser={currentUser}
        open={!correctingTransaction && selectedTransactionId != null}
        onCorrect={() => setCorrectingTransaction(true)}
        onClose={() => setSelectedTransactionId(null)}
      />
    </>
  );
}
