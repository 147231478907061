import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ChevronRight, Edit, EditOutlined, HelpOutline } from '@material-ui/icons';
import { aggregateClient } from 'clients/AggregateClient';
import { organizationStatsClient } from 'clients/OrganizationStatsClient';
import { transactionClient } from 'clients/TransactionClient';
import { userClient } from 'clients/UserClient';
import { Card } from 'components/Card';
import { DateIntervalSelector, defaultInterval } from 'components/DateIntervalSelector';
import { TransactionDialog } from 'components/dialogs/TransactionDialog';
import { Leaderboard } from 'components/Leaderboard';
import { LoadableContainer } from 'components/LoadableContainer';
import { Hide } from 'components/visibility/Hide';
import { DateTime } from 'luxon';
import { Audit } from 'models/Audit';
import { LeaderboardUser } from 'models/LeaderboardUser';
import { Transaction } from 'models/Transaction';
import { User } from 'models/User';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { abortEffect, handleRequest } from 'RequestHelpers';

const useStyles = makeStyles((theme) => ({
  dashboardCard: {
    padding: theme.spacing(3),
    height: '100%'
  },
  transactionsList: {
    flexBasis: '100%'
  }
}));

const correctedIcon = <Edit />;
const correctionIcon = <EditOutlined />;

export interface DashboardProps {
  user: User;
}

export interface CardProps {
  interval?: number;
}

function QuarterHeading(props: { interval: number }): React.ReactElement {
  const { interval } = props;

  return (
    <Typography variant="h6" component="h2">
      {defaultInterval.toDateString(interval)}
    </Typography>
  );
}

export function AdminCard(props: CardProps): React.ReactElement {
  const { interval = 0 } = props;
  const snackbar = useSnackbar();
  const [pointsAwarded, setPointsAwarded] = useState<number | null>();
  const [pointsRedeemed, setPointsRedeemed] = useState<number | null>();
  const [pointsBalance, setPointsBalance] = useState<number | null>();
  const [auditAverage, setAuditAverage] = useState<number | null>();

  useEffect(() => {
    setPointsAwarded(null);
    setPointsRedeemed(null);
    setAuditAverage(null);
    const query = defaultInterval.toDateQuery(interval);

    return abortEffect((signal) => {
      handleRequest(async () => {
        setPointsAwarded(await organizationStatsClient.getPointsAwarded({ query, signal }));
      }, snackbar);

      handleRequest(async () => {
        setPointsRedeemed(await organizationStatsClient.getPointsRedeemed({ query, signal }));
      }, snackbar);

      handleRequest(async () => {
        setAuditAverage(await organizationStatsClient.getAuditAverage({ query, signal }));
      }, snackbar);
    });
  }, [interval]);

  useEffect(() => {
    return abortEffect((signal) => {
      handleRequest(async () => {
        setPointsBalance(await organizationStatsClient.getPointsBalance({ signal }));
      }, snackbar);
    });
  }, []);

  return (
    <>
      <Grid container className="h-100" justify="center" alignItems="center">
        <Grid item className="text-align-center">
          <Typography variant="h4" component="h1">
            Organization Stats
          </Typography>
          <Typography variant="h5" component="div">
            Points Balance:{' '}
            <LoadableContainer inline data={pointsBalance} size={20}>
              {pointsBalance}
            </LoadableContainer>
          </Typography>
          <QuarterHeading interval={interval} />
          {[
            { title: 'Points Awarded', data: pointsAwarded },
            { title: 'Points Redeemed', data: pointsRedeemed },
            { title: 'Audit Average', data: auditAverage ? auditAverage.toFixed(0) : 'N/A' }
          ].map(({ title, data }, index) => (
            <Typography variant="h5" component="div" key={index}>
              {title}:{' '}
              <LoadableContainer inline data={data} size={20}>
                {data}
              </LoadableContainer>
            </Typography>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export function UserCard(props: DashboardProps & CardProps): React.ReactElement {
  const { user, interval = 0 } = props;
  const snackbar = useSnackbar();
  const [currentTransaction, setCurrentTransaction] = useState<Transaction | null>(null);
  const [pointTotal, setPointTotal] = useState<number | null>(null);
  const [auditAverage, setAuditAverage] = useState<number | null>(null);

  useEffect(() => {
    setCurrentTransaction(null);

    return abortEffect((signal) => {
      handleRequest(async () => {
        if (user.currentTransactionId == null) {
          setCurrentTransaction(new Transaction());
        } else {
          setCurrentTransaction(await transactionClient.get(user.currentTransactionId, { signal }));
        }
      }, snackbar);
    });
  }, [user]);

  useEffect(() => {
    const query = defaultInterval.toDateQuery(interval);
    setPointTotal(null);
    setAuditAverage(null);

    return abortEffect((signal) => {
      handleRequest(async () => {
        setPointTotal((await aggregateClient.getPointTotal(user.id, { query, signal })).value);
      }, snackbar);

      handleRequest(async () => {
        setAuditAverage((await aggregateClient.getAuditAverage(user.id, { query, signal })).value);
      }, snackbar);
    });
  }, [interval, user]);

  return (
    <>
      <Grid container className="h-100" justify="center" alignItems="center">
        <Grid item className="text-align-center">
          <Typography variant="h4" component="h1">
            Point Balance
          </Typography>
          <LoadableContainer data={currentTransaction} hideContent>
            <Typography variant="h1" component="div">
              {currentTransaction?.balance}
            </Typography>
            <QuarterHeading interval={interval} />
            <Typography variant="h5" component="div">
              Points Earned:{' '}
              <LoadableContainer inline data={pointTotal} size={20}>
                {pointTotal}
              </LoadableContainer>
            </Typography>
            <Typography variant="h5" component="div">
              Audit Average:{' '}
              <LoadableContainer inline data={auditAverage} size={20}>
                {auditAverage ? auditAverage.toFixed(0) : 'N/A'}
              </LoadableContainer>
            </Typography>
          </LoadableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export function Dashboard(props: DashboardProps): React.ReactElement {
  const { user } = props;
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [transactions, setTransactions] = useState<Transaction[] | null>(null);
  const [audits, setAudits] = useState<Audit[] | null>(null);
  const [pointsLeaderboardUsers, setPointsLeaderboardUsers] = useState<LeaderboardUser[] | null>(null);
  const [auditsLeaderboardUsers, setAuditsLeaderboardUsers] = useState<LeaderboardUser[] | null>(null);
  const [interval, setInterval] = useState<number>(0);

  useEffect(() => {
    const query = { limit: 5, ...defaultInterval.toDateQuery(interval) };
    setPointsLeaderboardUsers(null);
    setAuditsLeaderboardUsers(null);

    return abortEffect((signal) => {
      handleRequest(async () => {
        setAuditsLeaderboardUsers(await aggregateClient.getAuditsLeaderboard({ query, signal }));
      }, snackbar);

      handleRequest(async () => {
        setPointsLeaderboardUsers(await aggregateClient.getPointsLeaderboard({ query, signal }));
      }, snackbar);
    });
  }, [interval]);

  useEffect(() => {
    return abortEffect(async (signal) => {
      const afterDate = DateTime.local().minus({ weeks: 2 }).toISO();

      if (user.currentTransactionId == null) {
        setTransactions([]);
      } else {
        handleRequest(async () => {
          setTransactions(
            await userClient.getTransactions(user.id, {
              query: { include: 'template,correctedBy', createdAfter: afterDate },
              signal
            })
          );
        }, snackbar).then();
      }

      await handleRequest(async () => {
        setAudits(
          await userClient.getAudits(user.id, {
            query: { createdAfter: afterDate },
            signal
          })
        );
      }, snackbar);
    });
  }, [user]);

  function RenderTransactions(): React.ReactElement {
    const [transactionId, setTransactionId] = useState<number | null>();

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell padding="checkbox">
                <Tooltip
                  placement="top"
                  title={
                    <Grid container direction="column">
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>{correctedIcon}</Grid>
                        <Grid item>This transaction has been corrected</Grid>
                      </Grid>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>{correctionIcon}</Grid>
                        <Grid item>This transaction is a correction</Grid>
                      </Grid>
                    </Grid>
                  }
                >
                  <HelpOutline />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions?.map((transaction) => (
              <TableRow key={transaction.id} hover onClick={() => setTransactionId(transaction.id)}>
                <TableCell>{transaction.date.toLocaleString({ month: 'long', day: 'numeric' })}</TableCell>
                <TableCell>{transaction.template.name}</TableCell>
                <TableCell>
                  {transaction.amount >= 0 && '+'}
                  {transaction.amount}
                </TableCell>
                <TableCell>{transaction.balance}</TableCell>
                <TableCell padding="checkbox">
                  <Grid container justify="flex-end" wrap="nowrap">
                    <Grid item>
                      <Hide when={transaction.correctsId == null}>{correctionIcon}</Hide>
                      <Hide when={transaction.correctedBy == null}>{correctedIcon}</Hide>
                    </Grid>
                    <Grid item>
                      <ChevronRight />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TransactionDialog
          transactionId={transactionId}
          onTransactionIdChange={(id) => setTransactionId(id)}
          open={transactionId != null}
          onClose={() => setTransactionId(null)}
        />
      </>
    );
  }

  function RenderAudits(): React.ReactElement {
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Comment</TableCell>
              {/*<TableCell padding="checkbox" />*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {audits?.map((audit) => (
              <TableRow key={audit.id}>
                <TableCell>{audit.date.toLocaleString({ month: 'long', day: 'numeric' })}</TableCell>
                <TableCell>{audit.score}</TableCell>
                <TableCell className="pre-wrap">{audit.comment}</TableCell>
                {/*<TableCell padding="checkbox">*/}
                {/*  <ChevronRight />*/}
                {/*</TableCell>*/}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }

  return (
    <Grid container spacing={3} alignContent="stretch">
      <Grid item xs={12}>
        <DateIntervalSelector interval={interval} onIntervalChange={setInterval} />
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card className={classes.dashboardCard}>
          {user.hasAccessLevel('admin') ? (
            <AdminCard interval={interval} />
          ) : (
            <UserCard user={user} interval={interval} />
          )}
        </Card>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card className={classes.dashboardCard}>
          <div className="text-align-center">
            <Typography variant="h4" component="h1">
              Point Leaderboard
            </Typography>
            <QuarterHeading interval={interval} />
          </div>
          <LoadableContainer data={pointsLeaderboardUsers}>
            <Leaderboard label="Total" users={pointsLeaderboardUsers || []} currentUser={user} />
          </LoadableContainer>
        </Card>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card className={classes.dashboardCard}>
          <div className="text-align-center">
            <Typography variant="h4" component="h1">
              Audit Leaderboard
            </Typography>
            <QuarterHeading interval={interval} />
          </div>
          <LoadableContainer data={auditsLeaderboardUsers}>
            <Leaderboard label="Average" users={auditsLeaderboardUsers || []} currentUser={user} />
          </LoadableContainer>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={[classes.dashboardCard, classes.transactionsList].join(' ')}>
          <Typography variant="h4" component="h1">
            Recent Audits
          </Typography>
          <LoadableContainer data={audits}>
            <RenderAudits />
          </LoadableContainer>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={[classes.dashboardCard, classes.transactionsList].join(' ')}>
          <Typography variant="h4" component="h1">
            Recent Transactions
          </Typography>
          <LoadableContainer data={transactions}>
            <RenderTransactions />
          </LoadableContainer>
        </Card>
      </Grid>
    </Grid>
  );
}
