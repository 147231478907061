import { Grid } from '@material-ui/core';
import { aggregateClient } from 'clients/AggregateClient';
import { DateIntervalSelector, defaultInterval } from 'components/DateIntervalSelector';
import { Leaderboard } from 'components/Leaderboard';
import { TableView } from 'components/TableView';
import { config } from 'Config';
import { LeaderboardUser } from 'models/LeaderboardUser';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { abortEffect, handleRequest } from 'RequestHelpers';

export function AuditsLeaderboardView(): React.ReactElement {
  const snackbar = useSnackbar();
  const [leaderboard, setLeaderboard] = useState<LeaderboardUser[] | null>();
  const [interval, setInterval] = useState<number>(0);

  useEffect(() => {
    document.title = `${config.appName} Audits Leaderboard`;
  }, []);

  useEffect(() => {
    setLeaderboard(null);

    return abortEffect((signal) => {
      handleRequest(async () => {
        const leaderboard = await aggregateClient.getAuditsLeaderboard({
          query: defaultInterval.toDateQuery(interval),
          signal
        });
        setLeaderboard(leaderboard);
      }, snackbar);
    });
  }, [interval]);

  return (
    <>
      <TableView title="Audits Leaderboard" data={leaderboard}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <DateIntervalSelector interval={interval} onIntervalChange={setInterval} />
          </Grid>
          <Grid item>
            <Leaderboard users={leaderboard || []} label="Audit Average" stickyToolbar />
          </Grid>
        </Grid>
      </TableView>
    </>
  );
}
